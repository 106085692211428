import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import api from '@/lib/api'
import {
  getAccessToken,
  getSecureLogisticFromStorage,
} from '@/lib/getAccessToken'
import { NegotiateResponse } from './types'
import { queryClient } from '@/pages/_app'
import { ProfileResponse } from '../profile/types'
import { profileQueryKey } from '../profile/api'
import { SignalRContext } from './components/SignalRWrapper'

export const useMutateNegotiate = (options?: UseMutationOptions) => {
  const accessToken = getAccessToken()
  return useMutation<any, NegotiateResponse>({
    mutationFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/SignalR-MyTerminal-AZF/Negotiate`,
        {
          method: 'POST',
          headers: {
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        'STS',
      ),
    ...options,
  })
}

export const useMutateUnlinkGroup = (options?: UseMutationOptions) => {
  const SLSignIn = getSecureLogisticFromStorage()
  const profile = queryClient.getQueryData<ProfileResponse>(profileQueryKey)
  const connectionId = SignalRContext.connection?.connectionId

  // @ts-ignore
  return useMutation({
    mutationFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/SignalR-MyTerminal-AZF/UnlinkGroup`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
          body: JSON.stringify({
            connectionId,
            groupName: `SecureLogistics_${
              (profile && profile.profile.secureLogisticsId) ||
              (SLSignIn && SLSignIn.userId)
            }`,
          }),
        },
        profile && profile.profile.secureLogisticsId ? 'STS' : 'SL',
      ),
    ...options,
  })
}

export const useMutateLinkGroup = (options?: UseMutationOptions) => {
  const connectionId = SignalRContext.connection?.connectionId
  return useMutation<any, string, any>({
    mutationFn: (accessToken: string) =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/SignalR-MyTerminal-AZF/SecureLogisticsLinkGroup/${connectionId}`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
            Authorization: `Bearer ${accessToken}`,
          },
        },
        'SL',
      ),
    ...options,
  })
}
