import React, { useCallback, useEffect } from 'react'
import { useIsFetching } from '@tanstack/react-query'

import { useSecureLogisticsStore } from '../store'
import {
  getSecureLogisticFromStorage,
  isSecureLogisticsFromStorageValid,
} from '@/lib/getAccessToken'
import {
  profileQueryKey,
  useGetProfile,
  useMutateDeleteSLFromProfile,
} from '@/features/profile/api'
import { queryClient } from '@/pages/_app'
import {
  currentVisitQueryKey,
  historyVisitQueryKey,
} from '@/features/visit/api'
import {
  useMutateLinkGroup,
  useMutateUnlinkGroup,
} from '@/features/signalR/api'
import { useSignalRStore } from '@/features/signalR/store'
import { useAuthorizationStore } from '@/features/authorization/store'
import { devLog } from '@/lib/development'
import {
  useMutateSubscribeSecureLogistics,
  useMutateUnSubscribeSecureLogistics,
} from '@/features/notifications/api'
import { getAppVersion } from '@/lib/getAppValues'
import useLocalStorage from 'use-local-storage'
import { SLProfileResponse } from '@/features/authorization/types'
import { secureLogisticsDataKey } from '@/config'

interface Props {
  children: React.ReactNode
  loggedIn: boolean
}

const SecureLogistics: React.FC<Props> = ({ children, loggedIn }) => {
  // @ts-ignore
  const [SLSignInData, setSLSignInData] = useLocalStorage<
    SLProfileResponse | undefined
  >(secureLogisticsDataKey, undefined)
  const isFetchingHistoryVisits = useIsFetching({
    queryKey: historyVisitQueryKey,
  })
  const isFetchingCurrentVisits = useIsFetching({
    queryKey: currentVisitQueryKey,
  })

  const isLinked = useSignalRStore((state) => state.isLinked)
  const setIsReconnecting = useSignalRStore((state) => state.setIsReconnecting)
  const isReconnecting = useSignalRStore((state) => state.isReconnecting)
  const setIsDisconnecting = useSignalRStore(
    (state) => state.setIsDisconnecting,
  )
  const isDisconnecting = useSignalRStore((state) => state.isDisconnecting)
  const connectionId = useSignalRStore((state) => state.connectionId)
  const stsAccessToken = useAuthorizationStore((state) => state.accessToken)
  const accessToken = useSecureLogisticsStore((state) => state.accessToken)
  const unsubscribe = useSecureLogisticsStore((state) => state.unsubscribe)
  const setUnsubscribe = useSecureLogisticsStore(
    (state) => state.setUnsubscribe,
  )
  const setIsProfileDeleting = useSecureLogisticsStore(
    (state) => state.setIsProfileDeleting,
  )

  const setUserStateChangedIn = useSecureLogisticsStore(
    (state) => state.setUserStateChangedIn,
  )
  const setAccessToken = useSecureLogisticsStore(
    (state) => state.setAccessToken,
  )
  const setConnected = useSecureLogisticsStore((state) => state.setConnected)
  const userStateChangedIn = useSecureLogisticsStore(
    (state) => state.userStateChangedIn,
  )

  const { mutate: subscribeSecureLogistics } =
    useMutateSubscribeSecureLogistics()
  const { mutate: unsubscribeSecureLogistics } =
    useMutateUnSubscribeSecureLogistics()
  const { mutate: linkGroup } = useMutateLinkGroup()
  const { mutate: unlinkGroup } = useMutateUnlinkGroup({
    onSuccess: () => {
      setSLSignInData(undefined)
    },
  })
  const { mutate: unlinkGroupAndClearProfile } = useMutateUnlinkGroup({
    onSuccess: () => {
      setSLSignInData(undefined)

      queryClient.resetQueries(profileQueryKey)
    },
  })

  const { mutate: unlinkGroupAndDeleteSLFromProfile } = useMutateUnlinkGroup({
    onSuccess: () => {
      deleteSLFromProfile()
      setSLSignInData(undefined)
    },
  })
  const { mutate: deleteSLFromProfile, isSuccess } =
    useMutateDeleteSLFromProfile({
      onSuccess: () => {
        setIsProfileDeleting(false)
        refetchProfile()
      },
    })
  const { data: profile, refetch: refetchProfile } = useGetProfile({
    enabled: isSuccess,
  })

  const handleLinkgroup = useCallback(
    (token: string) => {
      if (connectionId) {
        linkGroup(token)
      }
    },
    [connectionId, linkGroup],
  )

  const handleUnlinkGroup = useCallback(
    (type?: 'clearProfile' | 'deleteSLFromProfile') => {
      if (type === 'clearProfile') {
        unlinkGroupAndClearProfile()
      } else if (type === 'deleteSLFromProfile') {
        unlinkGroupAndDeleteSLFromProfile()
      } else {
        unlinkGroup()
      }
    },
    [
      unlinkGroup,
      unlinkGroupAndClearProfile,
      unlinkGroupAndDeleteSLFromProfile,
    ],
  )

  // on mount Logged out flow
  useEffect(() => {
    if (!loggedIn) {
      devLog('🚛 1. on mount logged OUT')
      const isValid = isSecureLogisticsFromStorageValid()

      // If connected
      if (isValid) {
        const storageSecureLogistics = getSecureLogisticFromStorage()

        if (storageSecureLogistics) {
          setAccessToken(storageSecureLogistics?.accessToken)
        }
        setUserStateChangedIn('connected')
      } else {
        // If disconnected
        setUserStateChangedIn('disconnected')
      }
    }
  }, [loggedIn, setAccessToken, setUserStateChangedIn])

  // on mount Logged in flow
  useEffect(() => {
    if (loggedIn && profile) {
      devLog('🚛 2. on mount logged IN')
      // If connected
      if (profile.secureLogisticsId) {
        setUserStateChangedIn('connected')
      } else {
        // If disconnected
        setUserStateChangedIn('disconnected')
      }
    }
  }, [loggedIn, profile, setUserStateChangedIn])

  // Watch local storage for changes
  useEffect(() => {
    const handleStorageChange = () => {
      devLog('🚛 3. handleStorageChange')
      const storageSecureLogistics = getSecureLogisticFromStorage()

      // If secure logistics accessToken data has changed
      if (storageSecureLogistics?.accessToken !== accessToken) {
        if (storageSecureLogistics?.accessToken) {
          devLog('🚛 3.1. storageSecureLogistics?.accessToken')
          setAccessToken(storageSecureLogistics?.accessToken)
        }

        // If removed, set disconnected
        if (accessToken && !storageSecureLogistics) {
          devLog('🚛 3.2. storageSecureLogistics?.accessToken')
          setUserStateChangedIn('disconnected')
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [accessToken, setAccessToken, setUserStateChangedIn])

  // Logged Out & connected
  useEffect(() => {
    if (!loggedIn && userStateChangedIn === 'connected') {
      devLog('🚛 4. !loggedIn && userStateChangedIn === connected')
      if (accessToken) {
        handleLinkgroup(accessToken)
      }
      if (!!getAppVersion()) {
        subscribeSecureLogistics()
      }
      setConnected(true)
      setUserStateChangedIn('idle')
    }
  }, [
    accessToken,
    handleLinkgroup,
    loggedIn,
    setConnected,
    setUserStateChangedIn,
    stsAccessToken,
    subscribeSecureLogistics,
    userStateChangedIn,
  ])

  // Logged Out & disconnected
  useEffect(() => {
    if (!loggedIn && userStateChangedIn === 'disconnected') {
      devLog('🚛 5. !loggedIn && userStateChangedIn === disconnected')
      if (isLinked && accessToken) {
        devLog('🚛 5.1. isLinked && accessToken')
        if (profile) {
          handleUnlinkGroup('clearProfile')
        } else {
          handleUnlinkGroup()
        }
      } else {
        devLog('🚛 5.2. isLinked && accessToken')
        handleUnlinkGroup('clearProfile')
      }
      setSLSignInData(undefined)
      queryClient.removeQueries(historyVisitQueryKey)
      queryClient.removeQueries(currentVisitQueryKey)
      setConnected(false)
      setUserStateChangedIn('idle')
    }
  }, [
    accessToken,
    isLinked,
    loggedIn,
    profile,
    setConnected,
    setUserStateChangedIn,
    handleUnlinkGroup,
    userStateChangedIn,
    setSLSignInData,
  ])

  // Logged In & connected
  useEffect(() => {
    if (loggedIn && userStateChangedIn === 'connected') {
      devLog('🚛 6. loggedIn && userStateChangedIn === connected')
      if (isLinked) {
        handleUnlinkGroup()
      }

      if (stsAccessToken && profile?.secureLogisticsId) {
        handleLinkgroup(stsAccessToken)
      }

      setConnected(true)
      setSLSignInData(undefined)
      if (!!getAppVersion()) {
        subscribeSecureLogistics()
      }

      if (!isFetchingHistoryVisits) {
        queryClient.removeQueries(historyVisitQueryKey)
      }

      if (!isFetchingCurrentVisits) {
        queryClient.removeQueries(currentVisitQueryKey)
      }
      setUserStateChangedIn('idle')
    }
  }, [
    isFetchingHistoryVisits,
    isFetchingCurrentVisits,
    isLinked,
    handleLinkgroup,
    loggedIn,
    setConnected,
    setUserStateChangedIn,
    stsAccessToken,
    handleUnlinkGroup,
    userStateChangedIn,
    setSLSignInData,
    subscribeSecureLogistics,
    profile?.secureLogisticsId,
  ])

  // Logged In & disconnected
  useEffect(() => {
    if (loggedIn && userStateChangedIn === 'disconnected') {
      devLog('🚛 7. loggedIn && userStateChangedIn === disconnected')
      if (isLinked && !profile?.secureLogisticsId) {
        devLog('🚛 7.1. isLinked && !profile?.secureLogisticsId')
        handleUnlinkGroup()
      } else {
        devLog('🚛 7.2. isLinked && profile?.secureLogisticsId')
        handleUnlinkGroup('deleteSLFromProfile')
      }
      setConnected(false)
      setSLSignInData(undefined)
      queryClient.resetQueries(historyVisitQueryKey)
      queryClient.resetQueries(currentVisitQueryKey)
      setUserStateChangedIn('idle')
    }
  }, [
    isLinked,
    loggedIn,
    profile?.secureLogisticsId,
    setConnected,
    setUserStateChangedIn,
    handleUnlinkGroup,
    userStateChangedIn,
    setSLSignInData,
  ])

  // Link on reconnect
  useEffect(() => {
    if (isReconnecting && connectionId) {
      if (accessToken) {
        handleLinkgroup(accessToken)
      }
      if (stsAccessToken && profile?.secureLogisticsId) {
        handleLinkgroup(stsAccessToken)
      }
      setIsReconnecting(false)
    }
  }, [
    accessToken,
    connectionId,
    isReconnecting,
    handleLinkgroup,
    setIsReconnecting,
    stsAccessToken,
    profile?.secureLogisticsId,
  ])

  // Link after disconnect
  useEffect(() => {
    if (isDisconnecting && connectionId) {
      if (accessToken) {
        handleLinkgroup(accessToken)
      }
      if (stsAccessToken && profile?.secureLogisticsId) {
        handleLinkgroup(stsAccessToken)
      }
      setIsDisconnecting(false)
    }
  }, [
    accessToken,
    connectionId,
    isDisconnecting,
    handleLinkgroup,
    setIsDisconnecting,
    stsAccessToken,
    profile?.secureLogisticsId,
  ])

  // Unsubscribe
  useEffect(() => {
    if (unsubscribe && !!getAppVersion()) {
      unsubscribeSecureLogistics()
      setUnsubscribe(false)
    }
  }, [setUnsubscribe, unsubscribe, unsubscribeSecureLogistics])

  return <>{children}</>
}

export default SecureLogistics
