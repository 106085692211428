import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, styles } from 'ect-ui-react'

import { useSignalREffect } from './SignalRWrapper'
import { formatTime } from '@/lib/date'
import { useSignalRStore } from '../store'
import {
  getInstallationId,
  setInstallationId,
  getAppVersion,
  setAppVersion,
} from '@/lib/getAppValues'

const SignalRDebug: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState<any[]>([])
  const [installationIdValue, setInstallationIdValue] = useState<string>(
    getInstallationId() || '',
  )
  const [appVersionValue, setAppVersionValue] =
    useState<string>(getAppVersion())

  const connectionId = useSignalRStore((state) => state.connectionId)
  const connectionState = useSignalRStore((state) => state.connectionState)

  useSignalREffect(
    'Debug',
    (payload) => {
      setMessages([
        {
          date: formatTime(new Date().toString(), true),
          target: 'Debug',
          payload,
        },
        ...messages,
      ])
    },
    [messages],
  )

  useSignalREffect(
    'TruckVisitChanged',
    (payload) => {
      setMessages([
        {
          date: formatTime(new Date().toString(), true),
          target: 'TruckVisitChanged',
          payload,
        },
        ...messages,
      ])
    },
    [messages],
  )

  useSignalREffect(
    'RoutePlanAvailable',
    (payload) => {
      setMessages([
        {
          date: formatTime(new Date().toString(), true),
          target: 'RoutePlanAvailable',
          payload,
        },
        ...messages,
      ])
    },
    [messages],
  )

  useSignalREffect(
    'EIRAvailable',
    (payload) => {
      setMessages([
        {
          date: formatTime(new Date().toString(), true),
          target: 'EIRAvailable',
          payload,
        },
        ...messages,
      ])
    },
    [messages],
  )

  useSignalREffect(
    'Dosed',
    (payload) => {
      setMessages([
        {
          date: formatTime(new Date().toString(), true),
          target: 'Dosed',
          payload,
        },
        ...messages,
      ])
    },
    [messages],
  )

  useSignalREffect(
    'Undosed',
    (payload) => {
      setMessages([
        {
          date: formatTime(new Date().toString(), true),
          target: 'Undosed',
          payload,
        },
        ...messages,
      ])
    },
    [messages],
  )

  if (
    typeof window !== 'undefined' &&
    window.location.origin !== 'https://myterminal-mobile.ect.nl'
  ) {
    return (
      <Container>
        <button onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'x' : 'i'}</button>
        {isOpen && (
          <Panel>
            <b>Connection ID:</b> {connectionId}
            <br />
            <b>Connection state:</b> {connectionState}
            <List>
              {messages.map((m, i) => (
                <Item key={`debug-${i}`}>
                  <b>Target:</b> {m.target} - <Time>{m.date}</Time>
                  <br />
                  <b>Payload:</b>
                  {JSON.stringify(m.payload, null, 2)}
                </Item>
              ))}
            </List>
            <hr />
            <b>App values:</b>
            <br />
            <label htmlFor="INSTALLATION_ID">INSTALLATION_ID:</label>
            <Input
              name="INSTALLATION_ID"
              onChange={(e: any) => {
                setInstallationIdValue(e.target.value)
                setInstallationId(e.target.value)
              }}
              value={installationIdValue}
              placeholder=""
              type="text"
            />
            <label htmlFor="APP_VERSION">APP_VERSION:</label>
            <Input
              name="APP_VERSION"
              onChange={(e: any) => {
                setAppVersionValue(e.target.value)
                setAppVersion(e.target.value)
              }}
              value={appVersionValue}
              placeholder=""
              type="text"
            />
          </Panel>
        )}
      </Container>
    )
  }
  return null
}

export default SignalRDebug

const Container = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
`

const Panel = styled.div`
  padding: 16px;
  background-color: ${styles.colors.background};
  border-top: 1px solid ${styles.colors.greyPortThunderCloud40};
  max-height: 75vh;
  overflow-y: scroll;
`

const List = styled.ul`
  margin: 8px 0 0 0;
  padding: 0 16px;
`

const Item = styled.li`
  margin-bottom: 8px;
  line-height: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Time = styled.span`
  color: ${styles.colors.greyPortThunderCloud40};
`
