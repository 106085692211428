import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import api from '@/lib/api'
import { getAnyAccessToken } from '@/lib/getAccessToken'
import { getInstallationId } from '@/lib/getAppValues'

export const channelsQueryKey = ['channels']

export const useMutateSubscribeSecureLogistics = (
  options?: UseMutationOptions,
) => {
  const accessToken = getAnyAccessToken()

  return useMutation({
    mutationFn: () =>
      api(
        `${
          process.env.NEXT_PUBLIC_ECT_API_URL
        }/NHub-MyTerminal-AZF/Installations/${getInstallationId()}/SecureLogistics`,
        {
          method: 'POST',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    ...options,
  })
}

export const useMutateUnSubscribeSecureLogistics = (
  options?: UseMutationOptions,
) =>
  useMutation({
    retry: 3,
    retryDelay: 1000,
    mutationFn: () =>
      api(
        `${
          process.env.NEXT_PUBLIC_ECT_API_URL
        }/NHub-MyTerminal-AZF/Installations/${getInstallationId()}/SecureLogistics`,
        {
          method: 'DELETE',
          headers: {
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
      ),
    ...options,
  })

export const useGetChannels = (options?: UseQueryOptions) =>
  useQuery({
    queryKey: channelsQueryKey,
    queryFn: () =>
      api(
        `${
          process.env.NEXT_PUBLIC_ECT_API_URL
        }/NHub-MyTerminal-AZF/Installations/${getInstallationId()}/Channels`,
        {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
      ),
    select: (data: any) => data.channels,
    ...options,
  })
