import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { isDev } from '@/lib/development'

type UserStateType = 'idle' | 'connected' | 'disconnected'

type State = {
  accessToken?: string
  userStateChangedIn: UserStateType
  connected: boolean
  isProfileDeleting: boolean
  unsubscribe: boolean
}

type Actions = {
  setAccessToken: (token: string) => void
  setConnected: (connected: boolean) => void
  setUserStateChangedIn: (state: UserStateType) => void
  setIsProfileDeleting: (isProfileDeleting: boolean) => void
  setUnsubscribe: (unsubscribe: boolean) => void
}

export const useSecureLogisticsStore = create<State & Actions>((set) => ({
  unsubscribe: false,
  userStateChangedIn: 'idle',
  connected: false,
  isProfileDeleting: false,
  setAccessToken: (token: string) => set((state) => ({ accessToken: token })),
  setConnected: (connected: boolean) => set((state) => ({ connected })),
  setUserStateChangedIn: (userState: UserStateType) =>
    set((state) => ({
      userStateChangedIn: userState,
    })),
  setIsProfileDeleting: (isProfileDeleting: boolean) =>
    set((state) => ({ isProfileDeleting })),
  setUnsubscribe: (unsubscribe: boolean) => set((state) => ({ unsubscribe })),
}))

if (isDev()) {
  mountStoreDevtool('SecureLogisticsStore', useSecureLogisticsStore)
}
