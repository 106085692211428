import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { isDev } from '@/lib/development'

type State = {
  connectionId?: string | null
  connectionState?: string | null
  isLinked: boolean
  isDisconnecting: boolean
  isReconnecting: boolean
}

type Actions = {
  setIsLinked: (isLinked: boolean) => void
  setIsDisconnecting: (isDisconnecting: boolean) => void
  setIsReconnecting: (isReconnecting: boolean) => void
  setConnectionId: (connectionId?: string | null) => void
  setConnectionState: (connectionState?: string | null) => void
}

export const useSignalRStore = create<State & Actions>((set) => ({
  isDisconnecting: false,
  isReconnecting: false,
  isLinked: false,
  setIsDisconnecting: (isDisconnecting) => set(() => ({ isDisconnecting })),
  setIsReconnecting: (isReconnecting) => set(() => ({ isReconnecting })),
  setIsLinked: (isLinked) => set(() => ({ isLinked })),
  setConnectionId: (connectionId) => set(() => ({ connectionId })),
  setConnectionState: (connectionState) => set(() => ({ connectionState })),
}))

if (isDev()) {
  mountStoreDevtool('SignalRStore', useSignalRStore)
}
