import React from 'react'
import styled from 'styled-components'
import { Icon, styles } from 'ect-ui-react'
import { useRouter } from 'next/router'

interface Props {
  children: React.ReactNode
  retry?: boolean
}

const ErrorScreen: React.FC<Props> = ({ children, retry }) => {
  const router = useRouter()
  return (
    <>
      <Container>
        <div>
          <StyledIcon
            icon="crossCircle"
            color={styles.colors.redPortMorningRed}
          />
          <br />
          {children}
        </div>
      </Container>
      {retry && (
        <RetryButton onClick={() => router.reload()}>Retry</RetryButton>
      )}
    </>
  )
}

export default ErrorScreen

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  justify-content: center;
  text-align: center;
`

const StyledIcon = styled(Icon)`
  margin-bottom: 8px;
`

const RetryButton = styled.button`
  display: block;
`
