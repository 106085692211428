import React from 'react'
import styled from 'styled-components'
import { PulsingDots, styles } from 'ect-ui-react'

const LoadingScreen: React.FC = () => (
  <Container data-testid="loading-screen">
    <PulsingDots color={styles.colors.bluePortSea} size={16} />
  </Container>
)

export default LoadingScreen

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin: 24px 0;
`
