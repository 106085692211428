import { useAuthorizationStore } from '@/features/authorization/store'
import { useSecureLogisticsStore } from '@/features/secureLogistics/store'

const api = async (
  url: string,
  options: { [key: string]: any; body?: string },
  handle401?: 'STS' | 'SL',
) => {
  const response = await fetch(url, options)

  if (!response.ok) {
    if (response.status === 401) {
      useSecureLogisticsStore.getState().setUnsubscribe(true)
      if (handle401 === 'STS') {
        useAuthorizationStore.getState().setUserStateChangedIn('loggedOut')
        useSecureLogisticsStore.getState().setUnsubscribe(true)
      }
      if (handle401 === 'SL') {
        useSecureLogisticsStore.getState().setUserStateChangedIn('disconnected')
      }
    }

    const error = Error(`Network response was not ok - ${response.status}`)
    // @ts-ignore
    error.code = response.status
    // @ts-ignore
    error.cause = response.headers

    throw error
  }

  if (response.status === 200) {
    return response.json()
  }
}

export default api
