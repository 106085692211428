import format from 'date-fns/format'

export const formatDate = (date: string) =>
  date && format(new Date(date), 'dd-MM-yyyy - HH:mm')

export const formatTime = (date?: string | null, seconds?: boolean) =>
  date ? format(new Date(date), `HH:mm${!!seconds ? ':ss' : ''}`) : ''

export const formatDateOnly = (date: string) =>
  date && format(new Date(date), 'dd-MM-yyyy')
