import { Montserrat, Roboto_Mono } from 'next/font/google'
import { createGlobalStyle } from 'styled-components'

const montserrat = Montserrat({
  weight: ['400', '500', '600'],
  style: ['normal'],
  subsets: ['latin'],
})

const robotoMono = Roboto_Mono({
  weight: ['400'],
  style: ['normal'],
  subsets: ['latin'],
})

export const fonts = {
  primary: `${montserrat.style.fontFamily}, 'Arial', sans-serif`,
  secondary: `${robotoMono.style.fontFamily}, monospace `,
}

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #__next {
    padding: 0;
    margin: 0;
    height: 100%; /* for IE to behave */
    min-height: 100vh;
    font-family: ${fonts.primary};
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  * {
    font-family: ${fonts.primary};
    box-sizing: border-box;
  }

  [class^='styles_modal_'] {
    h2, ul, * {
      font-family: ${fonts.primary} !important;
    }
  }

  .react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 51px;
  height: 31px;
  padding: 0;
  border-radius: 30px;
  background-color: #E4E5E7;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #D0D0D4;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 21px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
}
`

export default GlobalStyle
