import React, { useEffect, useState } from 'react'
import { createSignalRContext } from 'react-signalr'

import { useMutateNegotiate } from '../api'
import ErrorScreen from '@/features/shared/ErrorScreen'
import LoadingScreen from '@/features/shared/LoadingScreen'
import { getAccessToken, removeAccessToken } from '@/lib/getAccessToken'
import { useSignalRStore } from '../store'
import SignalRDebug from './SignalRDebug'
import { useAuthorizationStore } from '@/features/authorization/store'

export const SignalRContext = createSignalRContext({
  shareConnectionBetweenTab: false,
})
export const useSignalREffect = SignalRContext.useSignalREffect

interface Props {
  children: React.ReactNode
}

const SignalRWrapper: React.FC<Props> = ({ children }) => {
  const [shouldNegotiate, setShouldNegotiate] = useState(true)

  const setIsLinked = useSignalRStore((state) => state.setIsLinked)
  const setIsReconnecting = useSignalRStore((state) => state.setIsReconnecting)
  const setIsDisconnecting = useSignalRStore(
    (state) => state.setIsDisconnecting,
  )
  const setConnectionId = useSignalRStore((state) => state.setConnectionId)
  const connectionId = useSignalRStore((state) => state.connectionId)
  const setConnectionState = useSignalRStore(
    (state) => state.setConnectionState,
  )
  const connectionState = useSignalRStore((state) => state.connectionState)
  const isLinked = useSignalRStore((state) => state.isLinked)
  const setLoggedIn = useAuthorizationStore((state) => state.setLoggedIn)
  const setUserStateChangedIn = useAuthorizationStore(
    (state) => state.setUserStateChangedIn,
  )

  const {
    mutate: negotiate,
    isLoading,
    error,
    data,
  } = useMutateNegotiate({
    onSuccess: () => setShouldNegotiate(false),
    onError: () => setShouldNegotiate(false),
  })

  const updateConnectionState = () => {
    const contextConnectionId = SignalRContext.connection?.connectionId
    const contextConnectionState = SignalRContext.connection?.state

    if (connectionId !== contextConnectionId) {
      setConnectionId(contextConnectionId)
    }
    if (connectionState !== contextConnectionState) {
      setConnectionState(contextConnectionState)
    }
  }

  useEffect(() => {
    if (shouldNegotiate) {
      negotiate()
    }
  }, [negotiate, shouldNegotiate])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (error) {
    setUserStateChangedIn('idle')
    setLoggedIn(false)
    removeAccessToken()
    return (
      <ErrorScreen>
        We were unable to retrieve the information you requested.
        <br />
        <br />
        Please try again by closing and opening the app.
      </ErrorScreen>
    )
  }

  return data ? (
    <SignalRContext.Provider
      connectEnabled={!!data.accessToken}
      accessTokenFactory={() => data!.accessToken}
      dependencies={[data.accessToken, !!getAccessToken()]} //remove previous connection and create a new connection if changed
      url={data.url}
      onOpen={() => {
        setIsLinked(true)
        updateConnectionState()
      }}
      onBeforeClose={() => {
        setIsLinked(false)
        updateConnectionState()
      }}
      onClosed={() => {
        updateConnectionState()
      }}
      onReconnect={() => {
        setIsReconnecting(true)
        updateConnectionState()
      }}
      onError={(e) =>
        new Promise((resolve) => {
          setIsDisconnecting(true)
          updateConnectionState()
          if (e && e.message.includes('401')) {
            setShouldNegotiate(true)
          }
          return resolve()
        })
      }
    >
      {!isLinked ? <LoadingScreen /> : <>{children}</>}
      <SignalRDebug />
    </SignalRContext.Provider>
  ) : null
}

export default SignalRWrapper
